<template>
  <b-modal
    :id="modalId"
    hide-header
    hide-footer
    hide-header-close
    size="lg"
    header-class="my-second-class"
    :body-bg-variant="'gray-lighten'"
  >
    <b-row class="pt-1 pb-1">
      <b-col align="left" class="popup-title">
        {{ null == walletRefill.id ? $t('views.wallet-refills.list.modal.create') : $t('views.wallet-refills.list.modal.update') }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey-dark mt-3 mb-3"/>
    <create-or-update-wallet-refill-form
      :wallet-refill="walletRefill"
      @on:wallet-refill-created-or-updated="$bvModal.hide(modalId); $emit('on:wallet-refill-created-or-updated')"
    />
  </b-modal>
</template>
<script>
import WalletRefill from "@/classes/doinsport/WalletRefill";
import CreateOrUpdateWalletRefillForm from "@form/wallet-refills/CreateOrUpdateWalletRefillForm";

const MODAL_ID = 'WALLET_REFILL_MODAL_ID';

export default {
  components: {CreateOrUpdateWalletRefillForm},
  props: {
    modalId: {
      type: String,
      default: MODAL_ID,
    },
    display: {
      type: Boolean,
      default: false,
    },
    walletRefill: {
      type: Object,
      default: () => new WalletRefill(),
    },
  },
  watch: {
    display: function () {
      this.$bvModal.show(this.modalId);
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@lazy/_modal.scss";
@media (min-width: 992px) {
  /deep/ .modal-lg, .modal-xl {
    max-width: 600px;
  }
}
</style>
