<template>
  <validation-observer
    slim
    ref="walletRefillObserver"
  >
    <b-row>
      <b-col cols="12">
        <label class="field-name-class">
          {{ $t('views.wallet-refills.list.modal.wallet-name') }}
        </label>
      </b-col>
      <b-col cols="12">
        <d-text-field
          v-model="walletRefill.name"
          :rules="validation.name"
          class-name="background-light-blue-inputs"
          size="sm"
          error-text="validation.text-input-three-characters"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="6">
        <label class="field-name-class">
          {{ $t('views.wallet-refills.list.modal.payment-price') }}
        </label>
      </b-col>
      <b-col cols="6">
        <label class="field-name-class">
          {{ $t('views.wallet-refills.list.modal.wallet-sold') }}
        </label>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-input-group>
          <b-form-input
            v-model="walletRefill.price"
            type="number"
            min="0"
            size="sm"
            value="0"
            autocomplete="off"
            class="background-light-blue-inputs"
          />
          <b-input-group-append is-text>
            <div> {{ $currency }}</div>
          </b-input-group-append>
        </b-input-group>
        <b-form-invalid-feedback
          :state="parseFloat(walletRefill.price) >= 0"
        >
          {{ $t('validation.required') }}
        </b-form-invalid-feedback>
      </b-col>
      <b-col cols="6">
        <b-input-group>
          <b-form-input
            v-model="walletRefill.amount"
            type="number"
            min="0"
            size="sm"
            value="0"
            autocomplete="off"
            class="background-light-blue-inputs"
          />
          <b-input-group-append is-text>
            <div> {{ $currency }}</div>
          </b-input-group-append>
        </b-input-group>
        <b-form-invalid-feedback
          :state="parseFloat(walletRefill.amount) >= 0"
        >
          {{ $t('validation.required') }}
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col align="right">
        <d-button
          :text="walletRefill.id ? 'general.actions.edit' : 'general.actions.add'"
          :disabled="isBusy ? 'disabled': ''"
          class="d-btn-sm font-text-title d-btn btn d-btn-primary-new font-text-title"
          @on:button-click="submit"
        />
      </b-col>
    </b-row>
  </validation-observer>
</template>
<script>
import WalletRefill from "@/classes/doinsport/WalletRefill";
import {postWallet, putWallet} from "@api/doinsport/services/wallet-refills/wallet-refills.api";

export default {
  data: () => ({
    isBusy: false,
    validation: require('@validation/entities/doinsport/WalletRefill.json'),
  }),
  props: {
    walletRefill: {
      type: Object,
      default: new WalletRefill()
    },
  },
  methods: {
    submit() {
      try {
        this.$refs.walletRefillObserver.validate().then((valid) => {
          if (
            !this.isBusy &&
            valid &&
            parseFloat(this.walletRefill.amount) >= 0 &&
            parseFloat(this.walletRefill.price) >= 0) {
            const serializedWalletRefill = new WalletRefill(this.walletRefill, {deserialize: true});

            this.isBusy = true;
            this.createOrUpdateWalletRefill(serializedWalletRefill)
              .then(() => {
                this.$emit('on:wallet-refill-created-or-updated');
              })
              .finally(() => {
                this.isBusy = false;
              })
            ;
          }
        });
      } catch (e) {
      }
    },
    createOrUpdateWalletRefill(serializedWalletRefill) {
      return this.walletRefill.id ? putWallet(serializedWalletRefill) : postWallet(serializedWalletRefill);
    }
  },
  created() {
    this.$store.dispatch('validator/set', this.validation);
  }
}
</script>
<style scoped lang="scss">
.input-group-text {
  line-height: 0;
}
</style>
