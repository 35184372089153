<template>
  <div>
    <d-body-top-bar :title="$t('views.wallet-refills.title')">
      <div slot="tree">
        <label class="inactive-tree">
          {{ $t('views.wallet-refills.benefit') }} /
        </label>
        <label class="bg-doinsport-primary ml-1"> {{ $t('views.wallet-refills.wallet-top-ups') }}</label>
      </div>
    </d-body-top-bar>
    <div class="container-fluid wallet-card-container">
      <b-card class="wallet-card">
      <b-row class="row-style"  no-gutters>
        <b-col :cols="innerWidth ? '12' : ''">
                <d-body-nav-bar
                  :tabs="$store.getters['menu/getTabs'].wallet"
                  @tab-changed="onTabChanged"
                />
        </b-col>
          <b-col
            :cols="innerWidth ? '6' : '4'"
            :class="innerWidth ? 'mt-3 pr-3' : 'text-right mr-2 mt-3'">
                <d-button
                  :text="'views.wallet-refills.add-new-wallet'"
                  class="ml-2 d-btn btn d-btn-primary font-text-title"
                  :class="innerWidth ? 'btn-full-width' : ''"
                  icon="fa fa-plus mr-1"
                  @on:button-click="newWalletRefill"
                />
            </b-col>
            <b-col
              :cols="innerWidth ? '6' : '2'"
              align="right"
              class="mt-3"
              :class="innerWidth ? 'pl-3' : ''"
            >
                <div class="btn-full-width has-search">
                  <span class="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    class="form-control"
                    v-model="filter"
                    :placeholder="$t('components.doinsport.table.search')"
                  >
                </div>
            </b-col>
          </b-row>
          <wallet-refills-list
            :reload="reload"
            :filter="filter"
            :is-archived="isArchived"
            @on:wallet-refill:update="updateWalletRefill"
          />
      <create-or-update-wallet-refill-modal
        :wallet-refill="walletRefill"
        :display="display"
        @on:wallet-refill-created-or-updated="reload =!reload"
      />
      </b-card>
    </div>
  </div>
</template>
<script>
import {getWallet} from "@api/doinsport/services/wallet-refills/wallet-refills.api";
import WalletRefill from "@/classes/doinsport/WalletRefill";
import CreateOrUpdateWalletRefillModal from "@custom/wallet-refills/CreateOrUpdateWalletRefillModal";

export default {
  data: () => ({
    walletRefill: new WalletRefill(),
    display: false,
    reload: false,
    filter: '',
    isArchived: false,
  }),
  components: {
    CreateOrUpdateWalletRefillModal,
    WalletRefillsList: () => import('./List')
  },
  computed:{
    innerWidth(){
      return this.$store.getters['layout/getInnerWidth'] < 700;
    }
  },
  methods: {
    onTabChanged(scope) {
      this.filter = '';
      this.isArchived = (scope.name === 'views.wallet-refills.wallet-rar' && scope.active)
    },
    newWalletRefill() {
      this.walletRefill = new WalletRefill();
      this.display = !this.display;
    },
    updateWalletRefill(scope) {
      getWallet(scope.id)
        .then((response) => {
          this.walletRefill = new WalletRefill(response.data, {serialize: true});
          this.display = !this.display;
        })
      ;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/_b-card.scss";

</style>
